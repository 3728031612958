var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Detail Kepemilikan"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Kavling"}},[_c('validation-provider',{attrs:{"name":"Nama Kavling","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nama Kavling","disabled":""},model:{value:(_vm.formDetail.kavling_name),callback:function ($$v) {_vm.$set(_vm.formDetail, "kavling_name", $$v)},expression:"formDetail.kavling_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nama Kavling Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Blok"}},[_c('validation-provider',{attrs:{"name":"Nama Blok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nama Blok","disabled":""},model:{value:(_vm.formDetail.block_name),callback:function ($$v) {_vm.$set(_vm.formDetail, "block_name", $$v)},expression:"formDetail.block_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nama Blok Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Pembelian"}},[_c('validation-provider',{attrs:{"name":"Tanggal Pembelian","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Pilih Tanggal Pembelian","config":_vm.configs.allowInput,"disabled":""},model:{value:(_vm.formDetail.purchase_date),callback:function ($$v) {_vm.$set(_vm.formDetail, "purchase_date", $$v)},expression:"formDetail.purchase_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Tanggal Pembelian Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Jatuh Tempo"}},[_c('validation-provider',{attrs:{"name":"Tanggal Jatuh Tempo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Pilih Tanggal Jatuh Tempo","config":_vm.configs.allowInput,"disabled":""},model:{value:(_vm.formDetail.due_date),callback:function ($$v) {_vm.$set(_vm.formDetail, "due_date", $$v)},expression:"formDetail.due_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Tanggal Jatuh Tempo Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cash DP"}},[_c('validation-provider',{attrs:{"name":"Cash DP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Cash DP","disabled":""},model:{value:(_vm.formDetail.down_payment_amount),callback:function ($$v) {_vm.$set(_vm.formDetail, "down_payment_amount", $$v)},expression:"formDetail.down_payment_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Cash DP Wajib Diisi" : ""))]),(_vm.formDetail.down_payment_amount)?_c('small',[_vm._v(" Rp. "+_vm._s(_vm.price2)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Harga"}},[_c('b-form-input',{attrs:{"placeholder":"Harga","disabled":""},model:{value:(_vm.formDetail.total_price),callback:function ($$v) {_vm.$set(_vm.formDetail, "total_price", $$v)},expression:"formDetail.total_price"}}),(_vm.formDetail.total_price)?_c('small',[_vm._v(" Rp. "+_vm._s(_vm.price3)+" ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Pembeli"}},[_c('validation-provider',{attrs:{"name":"Nama Pembeli","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nama Pembeli","disabled":""},model:{value:(_vm.formDetail.name),callback:function ($$v) {_vm.$set(_vm.formDetail, "name", $$v)},expression:"formDetail.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nama Pembeli Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fasilitas"}},[_c('validation-provider',{attrs:{"name":"Fasilitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Fasilitas","disabled":""},model:{value:(_vm.formDetail.facility),callback:function ($$v) {_vm.$set(_vm.formDetail, "facility", $$v)},expression:"formDetail.facility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Fasilitas Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NIK"}},[_c('validation-provider',{attrs:{"name":"NIK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"NIK","disabled":""},model:{value:(_vm.formDetail.identity_number),callback:function ($$v) {_vm.$set(_vm.formDetail, "identity_number", $$v)},expression:"formDetail.identity_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "NIK Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Marketing"}},[_c('validation-provider',{attrs:{"name":"Nama Marketing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nama Marketing","disabled":""},model:{value:(_vm.formDetail.marketing_name),callback:function ($$v) {_vm.$set(_vm.formDetail, "marketing_name", $$v)},expression:"formDetail.marketing_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nama Marketing Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alamat Pembeli"}},[_c('validation-provider',{attrs:{"name":"Alamat Pembeli","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Alamat Pembeli","disabled":""},model:{value:(_vm.formDetail.address),callback:function ($$v) {_vm.$set(_vm.formDetail, "address", $$v)},expression:"formDetail.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Alamat Pembeli Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Telepon"}},[_c('validation-provider',{attrs:{"name":"Nomor Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nomor Telepon","disabled":""},model:{value:(_vm.formDetail.phone),callback:function ($$v) {_vm.$set(_vm.formDetail, "phone", $$v)},expression:"formDetail.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Nomor Telepon Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tempat Lahir"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Tempat Lahir","disabled":""},model:{value:(_vm.formDetail.place_of_birth),callback:function ($$v) {_vm.$set(_vm.formDetail, "place_of_birth", $$v)},expression:"formDetail.place_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Tempat Lahir Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Lahir"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Pilih Tanggal Lahir","config":_vm.configs.allowInput,"disabled":""},model:{value:(_vm.formDetail.date_of_birth),callback:function ($$v) {_vm.$set(_vm.formDetail, "date_of_birth", $$v)},expression:"formDetail.date_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Tanggal Lahir Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kewarganegaraan"}},[_c('validation-provider',{attrs:{"name":"Kewarganegaraan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Kewarganegaraan","disabled":""},model:{value:(_vm.formDetail.nationality),callback:function ($$v) {_vm.$set(_vm.formDetail, "nationality", $$v)},expression:"formDetail.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Kewarganegaraan Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Status","disabled":""},model:{value:(_vm.formDetail.marital_status),callback:function ($$v) {_vm.$set(_vm.formDetail, "marital_status", $$v)},expression:"formDetail.marital_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? "Status Wajib Diisi" : ""))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Catatan"}},[_c('b-form-textarea',{attrs:{"rows":"3","placeholder":"Masukkan Catatan","disabled":""},model:{value:(_vm.formDetail.note),callback:function ($$v) {_vm.$set(_vm.formDetail, "note", $$v)},expression:"formDetail.note"}})],1)],1)],1),(this.lastPathParams != "form")?_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"3","align":"start"}},[_c('b',[_vm._v(" Detail Angsuran ")])]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"6","align":"start"}}),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"3","align":"end"}})],1):_vm._e(),(this.lastPathParams != "form")?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
              enabled: false,
              externalQuery: _vm.searchTerm,
            },"pagination-options":{
              enabled: false,
            },"line-numbers":true},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'payment_name')?_c('span',[_vm._v(" "+_vm._s(props.row.payment_name)+" ")]):_vm._e(),(props.column.field === 'note')?_c('span',[_vm._v(" "+_vm._s(props.row.note)+" ")]):_vm._e(),(props.column.field === 'payment_date')?_c('span',[_vm._v(" "+_vm._s(props.row.payment_date.substring(0, 10))+" ")]):_vm._e(),(props.column.field === 'payment_amount')?_c('span',[_vm._v(" Rp. "+_vm._s(props.row.payment_amount .toString() .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\."))+" ")]):_vm._e()]}}],null,false,3542248416)})],1)],1):_vm._e(),(this.lastPathParams != "form")?_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"6","align":"start"}}),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"3","align":"end"}},[_vm._v(" Total Masuk: Rp. "+_vm._s(this.total_paid .toString() .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\."))+" ")]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"3","align":"end"}},[_vm._v(" Kekurangan: Rp. "+_vm._s(this.total_unpaid .toString() .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\."))+" ")])],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","align":"end"}},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.backButton()}}},[_vm._v(" Kembali ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }