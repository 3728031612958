<template>
  <b-card-code title="Detail Kepemilikan">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nama Kavling">
              <validation-provider
                #default="{ errors }"
                name="Nama Kavling"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.kavling_name"
                  placeholder="Nama Kavling"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Kavling Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nama Blok">
              <validation-provider
                #default="{ errors }"
                name="Nama Blok"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.block_name"
                  placeholder="Nama Blok"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Blok Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Pembelian">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Pembelian"
                rules="required"
              >
                <flat-pickr
                  v-model="formDetail.purchase_date"
                  class="form-control"
                  placeholder="Pilih Tanggal Pembelian"
                  :config="configs.allowInput"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Pembelian Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Jatuh Tempo">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Jatuh Tempo"
                rules="required"
              >
                <flat-pickr
                  v-model="formDetail.due_date"
                  class="form-control"
                  placeholder="Pilih Tanggal Jatuh Tempo"
                  :config="configs.allowInput"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Jatuh Tempo Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="6">
            <b-form-group label="Jumlah Angsuran">
              <validation-provider
                #default="{ errors }"
                name="Jumlah Angsuran"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.installment_count"
                  placeholder="Jumlah Angsuran"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Jumlah Angsuran Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <b-form-group label="Cash DP">
              <validation-provider
                #default="{ errors }"
                name="Cash DP"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.down_payment_amount"
                  placeholder="Cash DP"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Cash DP Wajib Diisi" : ""
                }}</small>

                <small v-if="formDetail.down_payment_amount">
                  Rp. {{ price2 }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Harga">
              <b-form-input
                placeholder="Harga"
                disabled
                v-model="formDetail.total_price"
              />

              <small v-if="formDetail.total_price"> Rp. {{ price3 }} </small>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Nama Pembeli">
              <validation-provider
                #default="{ errors }"
                name="Nama Pembeli"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.name"
                  placeholder="Nama Pembeli"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Pembeli Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Fasilitas">
              <validation-provider
                #default="{ errors }"
                name="Fasilitas"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.facility"
                  placeholder="Fasilitas"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Fasilitas Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="NIK">
              <validation-provider
                #default="{ errors }"
                name="NIK"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.identity_number"
                  placeholder="NIK"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "NIK Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nama Marketing">
              <validation-provider
                #default="{ errors }"
                name="Nama Marketing"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.marketing_name"
                  placeholder="Nama Marketing"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Marketing Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Alamat Pembeli">
              <validation-provider
                #default="{ errors }"
                name="Alamat Pembeli"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.address"
                  placeholder="Alamat Pembeli"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Alamat Pembeli Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nomor Telepon">
              <validation-provider
                #default="{ errors }"
                name="Nomor Telepon"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.phone"
                  placeholder="Nomor Telepon"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nomor Telepon Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tempat Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.place_of_birth"
                  placeholder="Tempat Lahir"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tempat Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tanggal Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Lahir"
                rules="required"
              >
                <flat-pickr
                  v-model="formDetail.date_of_birth"
                  class="form-control"
                  placeholder="Pilih Tanggal Lahir"
                  :config="configs.allowInput"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Kewarganegaraan">
              <validation-provider
                #default="{ errors }"
                name="Kewarganegaraan"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.nationality"
                  placeholder="Kewarganegaraan"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Kewarganegaraan Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-input
                  v-model="formDetail.marital_status"
                  placeholder="Status"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Status Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Catatan">
              <b-form-textarea
                rows="3"
                v-model="formDetail.note"
                placeholder="Masukkan Catatan"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="3" align="start" class="mt-2">
            <b> Detail Angsuran </b></b-col
          >

          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2"> </b-col>
        </b-row>

        <b-row class="mt-1" v-if="this.lastPathParams != `form`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'payment_name'">
                  {{ props.row.payment_name }}
                </span>

                <span v-if="props.column.field === 'note'">
                  {{ props.row.note }}
                </span>

                <span v-if="props.column.field === 'payment_date'">
                  {{ props.row.payment_date.substring(0, 10) }}
                </span>

                <span v-if="props.column.field === 'payment_amount'">
                  Rp.
                  {{
                    props.row.payment_amount
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>

        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2">
            Total Masuk: Rp.
            {{
              this.total_paid
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </b-col>

          <b-col cols="3" align="end" class="mt-2">
            Kekurangan: Rp.
            {{
              this.total_unpaid
                .toString()
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
            }}
          </b-col>
        </b-row>

        <b-row>
          <!-- button -->
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()">
              Kembali
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      email,

      kavlingOptions: [],
      blokOptions: [],
      marketingOptions: [],

      statusOptions: [
        {
          _id: "KAWIN",
          name: "KAWIN",
        },
        {
          _id: "BELUM_KAWIN",
          name: "BELUM KAWIN",
        },
        {
          _id: "JANDA",
          name: "JANDA",
        },
        {
          _id: "DUDA",
          name: "DUDA",
        },
      ],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      price2: "",
      price3: "",

      lastPathParams: "",

      formDetail: {
        kavling_name: "",
        block_name: [],
        purchase_date: "",
        installment_count: 0,
        down_payment_amount: 0,
        facility: "",
        name: "",
        phone: "",
        address: "",
        identity_number: "",
        nationality: "",
        marital_status: "",
        date_of_birth: "",
        place_of_birth: "",
        note: "",
        marketing_name: "",
        due_date: "",
        total_price: "",
      },

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],
      searchTerm: "",

      total_paid: "",
      total_unpaid: "",

      rows: [],
      columns: [
        {
          label: "Item",
          field: "payment_name",
          thClass: "text-center",
        },
        {
          label: "Tanggal Pembayaran",
          field: "payment_date",
          thClass: "text-center",
        },
        {
          label: "Nominal",
          field: "payment_amount",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "note",
          thClass: "text-center",
        },
      ],
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    if (this.lastPathParams === "detail") {
      this.getDetailData();
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    getDetailData() {
      return new Promise(() => {
        axios
          .get("v1/ownership/" + this.$route.params.id)
          .then((res) => {
            this.formDetail.kavling_name = res.data.kavling_name;
            this.formDetail.block_name = res.data.block_name;
            this.formDetail.purchase_date = res.data.purchase_date;
            this.formDetail.installment_count = res.data.installment_count;
            this.formDetail.down_payment_amount = res.data.down_payment_amount;
            this.formDetail.facility =
              res.data.facility == null ? "-" : res.data.facility;
            this.formDetail.name = res.data.name;
            this.formDetail.phone = res.data.phone;
            this.formDetail.address = res.data.address;
            this.formDetail.identity_number = res.data.identity_number;
            this.formDetail.nationality = res.data.nationality;
            this.formDetail.marital_status = res.data.marital_status;
            this.formDetail.date_of_birth = res.data.date_of_birth;
            this.formDetail.place_of_birth = res.data.place_of_birth;
            this.formDetail.note = res.data.note;
            this.formDetail.marketing_name = res.data.marketing_name;
            this.formDetail.due_date = res.data.due_date;
            this.formDetail.total_price = res.data.total_price;

            this.total_paid = res.data.total_paid;
            this.total_unpaid = res.data.total_unpaid;

            this.handleInputHarga();
            this.handleInputHarga3();

            this.rows = res.data.payment_history;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formDetail.down_payment_amount);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    handleInputHarga3() {
      this.price3 = this.format2(this.formDetail.total_price);
      this.$emit("input", (this.price3 + "").replace(/[^0-9]/g, ""));
    },

    backButton() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
